import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCardFlip from 'react-card-flip';
const Registro = () => {
    /*Declaracion de variables para obtener el token */
    const [clientToken, setClientToken] = useState([]);
    /* Declaracion de variables */
    const [showButton, setShowButton] = useState(false);
    const [idCuentaHabiente, setIdCuentaHabiente] = useState("");
    const [idCuentaHabienteOk, setIdCuentaHabienteOk] = useState(false);
    const [idUser, setIdUser] = useState("");
    /* Variables del fomulario */
    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [calle, setCalle] = useState("");
    const [numeroExterior, setNumeroExterior] = useState("");
    const [btwCalles, setBtwCalles] = useState("");
    const [colonia, setColonia] = useState("");
    const [municipio, setMunicipio] = useState("");
    const [cp, setCP] = useState("");
    const [estado, setEstado] = useState("");
    const [ciudad, setCiudad] = useState("");
    const maxLength = 1500;
    const [text, setText] = useState('');
    const [documentPDF, setDocumentPDF] = useState("");
    const [files, setFiles] = useState([]);
    const [isFlipped, setIsFlipped] = useState(false);
    /* Fin declaracion de variables */
    /* Datos para crear el cuentaHabiente */
    const [nombreCuenta, setNombreCuenta] = useState("")
    const [apellidoCuenta, setApellidoCuenta] = useState("")
    const [correoCuenta, setCorreoCuenta] = useState("")
    /* Varibale del Captcha */
    const [scripOK, setScriptOK] = useState(false)
    /* Fin variable Captcha */
    /* Variable de datos estan llenos */
    const [dataOK, setDataOK] = useState(false)
    const [sendDataOK, setSendDataOK] = useState(false)
    const [isConsultCuentaOk, setIsConsultCuentaOk] = useState(false)
    /* Script del rcapcha */
    useEffect(() => {
        /* Script rcapcha */
        var script = window.document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lcups4pAAAAADcIz_NPevvOOaB-kQQiiyGb5JIJ";
        window.document.getElementsByTagName('head')[0].appendChild(script);
        setScriptOK(true)
    }, [])
    /* Fin del Script del rCapcha */
    /* Verificar capcha */
    function Verificar() {
        setDataOK(true);
        if (scripOK === true) {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute('6Lcups4pAAAAADcIz_NPevvOOaB-kQQiiyGb5JIJ', { action: 'submit' }).then(function (token) {
                    //setTokenCap(token)
                    sendData(token);
                });
            });
        }
    }
    /* Fin de la funcion de  Verificar capcha */
    /* Obtener Variables con el onChange */
    function onChangeData(e) {
        const element = window.document.getElementById("idcuenta");

        if (e.target.id === "nombreCuenta") { setNombreCuenta(e.target.value); }
        if (e.target.id === "apellidoCuenta") { setApellidoCuenta(e.target.value); }
        if (e.target.id === "correoCuenta") { setCorreoCuenta(e.target.value); }

        if (e.target.id === "idcuenta") {
            element.classList = "form-control formulario mb-1";
            setIdCuentaHabiente(e.target.value);
            setIdCuentaHabienteOk(false);
        }
        if (e.target.id === "nombre") { setNombre(e.target.value); }
        if (e.target.id === "telefono") { setTelefono(e.target.value); }
        if (e.target.id === "email") { setEmail(e.target.value); }
        if (e.target.id === "calle") { setCalle(e.target.value); }
        if (e.target.id === "numeroExterior") { setNumeroExterior(e.target.value); }
        if (e.target.id === "btwcalles") { setBtwCalles(e.target.value); }
        if (e.target.id === "colonia") { setColonia(e.target.value); }
        if (e.target.id === "municipio") { setMunicipio(e.target.value); }
        if (e.target.id === "cp") { setCP(e.target.value); }
        if (e.target.id === "ciudad") { setCiudad(e.target.value); }
        if (e.target.id === "state") { setEstado(e.target.value); }
        if (e.target.id === "historia") { setText(e.target.value) }
    }
    /* Obtener Variables con el onChange */
    /* Token */
    useEffect(() => {
        axios({
            url: process.env.REACT_APP_API + "/oauth/token",
            method: "POST",
            data: {
                grant_type: "client_credentials",
                client_id: process.env.REACT_APP_CLIENT,
                client_secret: process.env.REACT_APP_SECRET
            }
        }).then((response) => {
            setClientToken(response.data.access_token);
        }).catch((e) => {
            console.error("Error al obtener el token de autenticación");
        });
    }, []);
    /* Fin token */
    /* Validar el cuentahabiente una vez agregado el id sin necesidad del boton */
    function validarCuentahabiente() {
        const element = window.document.getElementById("idcuenta");
        console.log(idCuentaHabienteOk);
        console.log(idCuentaHabiente);
        if (idCuentaHabienteOk == false) {
            if (idCuentaHabiente !== "") {
                axios({
                    url: process.env.REACT_APP_API + '/api/users/account_num/' + idCuentaHabiente,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.data.status === true) {
                            toast.success('Id del CuentaHabiente Validado', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            console.log(response.data.data);
                            setIdUser(response.data.data.id);
                            setIdCuentaHabienteOk(true);
                            element.classList = "form-control formulario mb-1 border-success";
                        } else {
                            toast.error('"No existe el CuentaHabiente"', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",

                            });
                            setIdCuentaHabienteOk(false);
                            element.classList = "form-control formulario mb-1 border-danger";
                        }
                    })
                    .catch((e) => {
                        console.error("error de axios " + e);
                    })
            } else {
                console.log("El ID no fue validado");
            }
        }

    }
    /* Fin del metodo de Validar el Cuentahabiente */
    /* Subir pdf o imagen del predio*/
    function SendDocument(e) {
        const element = window.document.getElementById("filePDF");
        console.log("Entrando a envio de img o PDF");
        let files = e.target.files || e.dataTransfer.files;
        var formData = new FormData();
        let fileType = files[0].type;
        if (fileType === undefined) {
            console.log("No se selcciono ningun archivo");
        } else {
            if (fileType === "application/pdf") {
                console.log("Es formato pdf");
                formData.append("document", files[0]);
            } else if (fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg") {
                console.log("es imagen");
                formData.append("picture", files[0]);
            } else {
                console.log("No se encuentra el tipo");
            }
        }

        axios({
            url: process.env.REACT_APP_API + "/api/elc/upload-picture-document",
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: formData,
        })
            .then((response) => {
                //console.log(response);
                setDocumentPDF(response.data.fileName)
                element.classList = "upload-button-success";
                element.innerHTML = "Documento agregado";
            })
            .catch((e) => {
                console.error(e);
            })
    }
    /* fin del metodo subir pdf o imagen del predio*/
    /* Si en caso de que el usuario no tenga id pude obtenerlo con el sigunete metodo */
    async function EnviarDatosCuentaHabiente() {
        var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (nombreCuenta !== "" && correoCuenta !== "" && apellidoCuenta !== "") {
            if (validEmail.test(correoCuenta)) {
                let data = {
                    "brand_id": 1,
                    "name": nombreCuenta,
                    "lastname": apellidoCuenta,
                    "email": correoCuenta,
                }
                axios({
                    url: process.env.REACT_APP_API + '/api/users',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + clientToken
                    },
                    data,
                }).then((response) => {
                    if (response.data.status === true) {
                        setIdCuentaHabiente(response.data.data.account_num);
                        console.log(response.data.data.account_num);
                        setIdUser(response.data.data.id);
                        setIsConsultCuentaOk(true)
                        setIsFlipped(false);
                        handleCleanID()
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }).catch((e) => {
                    console.error(e);
                })
            } else {
                toast.error("El formato del correo electronico es incorrecto", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Llena el campos solicitados", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    /* Fin de obtener idCuentahabiente */
    /*Envio de todos los datos para registrar*/
    function sendData(token) {
        var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        var validPhone = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
        if (idCuentaHabienteOk == true) {
            if (files.length !== 0) {
                if (documentPDF !== "" && nombre !== "" && telefono !== "" && email !== "" && calle !== ""
                    && numeroExterior !== "" && btwCalles !== "" && colonia !== "" && municipio !== ""
                    && cp !== "" && ciudad !== "" && estado !== "" && text !== "") {
                    if (validPhone.test(telefono)) {
                        if (validEmail.test(email)) {
                            let data = {
                                "user_id": idUser,
                                "microsite_id": 113,
                                "name": nombre,
                                "phone": telefono,
                                "legal_documentation": documentPDF,
                                "legal_identification": null,
                                "picture": null,
                                "story": text,
                                "address": {
                                    "street": calle,
                                    "city": ciudad,
                                    "cp": cp,
                                    "no_ext": numeroExterior,
                                    "state": estado,
                                    "suburb": colonia,
                                    "municipality": municipio,
                                    "btw_streets": btwCalles,
                                    "reference": null
                                },
                                "token": token
                            }
                            console.log(data);
                            axios({
                                url: process.env.REACT_APP_API + '/api/elc',
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                }, data,
                            })
                                .then((response) => {
                                    if (response.data.status === true) {
                                        let idUserGallery = response.data.register.id;
                                        console.log(response.data.register.id);
                                        let img = {
                                            'gallery':
                                                files
                                        }
                                        axios({
                                            url: process.env.REACT_APP_API + "/api/elc/" + idUserGallery + "/upload-gallery",
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                                'Accept': 'application/json'
                                            },
                                            data: img,
                                        })
                                            .then((response) => {
                                                if (response.data.status == true) {
                                                    setSendDataOK(true);
                                                    toast.success("Registro Exitoso", {
                                                        position: "top-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                } else {
                                                    toast.error(response.data.message, {
                                                        position: "top-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                    });
                                                }
                                            })
                                            .catch((e) => {
                                                console.error(e);
                                            })
                                    } else {
                                        setDataOK(false);
                                        toast.error(response.data.message, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }
                                })
                                .catch((e) => {
                                    console.error("error de axios " + e);
                                })

                        } else {
                            setDataOK(false);
                            toast.warning('El formato del correo electrónico esta incorrecto', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    } else {
                        setDataOK(false);
                        toast.warning('El formato del número telefonico está incorrecto', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }

                } else {
                    setDataOK(false);
                    toast.warning('Llena los campos solicitados', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                setDataOK(false);
                toast.warning('Agrega las imagenes de su domicilio', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            setDataOK(false);
            toast.warning('Valida el Id de CuentaHabiente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }

    }
    /* Fin de Envio de todos los datos para registrar */
    /* DropZone*/
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };


    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    className='imagenPreview'
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
    /* fin DropZone*/
    /* Manejar cambio en el checkbox*/
    const handleCheckboxChange = () => {
        setShowButton(!showButton);
    };
    /*fin Manejar cambio en el checkbox*/
    const twowns = [
        "Extranjero",
        "Aguascalientes",
        "Baja California",
        "Baja California Sur",
        "Campeche",
        "Chiapas",
        "Chihuahua",
        "Coahuila",
        "Colima",
        "CDMX",
        "Durango",
        "Guanajuato",
        "Guerrero",
        "Hidalgo",
        "Jalisco",
        "México",
        "Michoacan",
        "Morelos",
        "Nayarit",
        "Nuevo Leon",
        "Oaxaca",
        "Puebla",
        "Querétaro",
        "Quintana Roo",
        "San Luis Potosi",
        "Sinaloa",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Veracruz",
        "Yucatan",
        "Zacatecas",
    ];
    /* Al terminar de resgitrar el id de cuenta se limpiaran los campos */
    function handleCleanID() {
        setNombreCuenta("");
        setApellidoCuenta("");
        setCorreoCuenta("");
        ///setIdCuentaHabienteOk(true);
    }
    useEffect(() => { }, [idCuentaHabienteOk])
    useEffect(() => { }, [idCuentaHabiente])
    useEffect(() => {
        if (isConsultCuentaOk == true) {
            validarCuentahabiente();
        }
    }, [isConsultCuentaOk])
    return (
        <>
            <ToastContainer />
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <div id="registro" className="section">
                    {sendDataOK == true
                        ?
                        <div className='container sectionThanks'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <h1 className='titulos mb-5'>Gracias por registrarte</h1>
                                    <img src={process.env.PUBLIC_URL + "/images/assets/logoPaloma.gif"} className='imgThanks' />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container mt-5 mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="titulos text-center mb-3">Registro</h1>
                                    <h1 className="textoRegistro text-center mb-5">Estás a un paso de participar por una transformación total para la casa de tu mamá</h1>
                                </div>
                                <div className="col-12">
                                    <div className="container contenedor">
                                        <div className="row">
                                            <p className="form-text text-start fw-bold"><a onClick={() => setIsFlipped((prev) => !prev)} target="_blank" className='text-decoration-none text-light'>SACA AQUÍ TU ID</a></p>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" id="idcuenta" placeholder="ID del Cuentahabiente" value={idCuentaHabiente} onChange={onChangeData} onBlur={() => validarCuentahabiente()} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" id="nombre" placeholder="Nombre del Participante" value={nombre} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="number" className="form-control formulario mb-1" id="telefono" placeholder="Teléfono" value={telefono} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="email" className="form-control formulario mb-1" id="email" placeholder="Correo Electrónico" value={email} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" name="calle" id='calle' placeholder="Calle" value={calle} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" name="numeroExterior" id='numeroExterior' placeholder="Número Exterior" value={numeroExterior} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <input type="text" className="form-control formulario mb-1" name="btwCallea" id='btwcalles' placeholder="Entre que Calles aledañas" value={btwCalles} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" name="colonia" id='colonia' placeholder="Colonia" value={colonia} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" name="municipio" placeholder="Municipio" id='municipio' value={municipio} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" name="cp" id='cp' placeholder="Código Postal" value={cp} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <input type="text" className="form-control formulario mb-1" name="ciudad" id='ciudad' placeholder="Ciudad" value={ciudad} onChange={onChangeData} />
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <select id="state" className="form-select formulario text-center" name="state" value={estado} onChange={onChangeData}>
                                                    <option value="">Selecciona un estado</option>
                                                    {twowns.map((state, index) => (
                                                        <option key={index} value={state}>{state}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-12 col-sm-12'>
                                                <textarea
                                                    id='historia'
                                                    className="form-control formulario mb-1 "
                                                    value={text}
                                                    onChange={onChangeData}
                                                    maxLength={maxLength}
                                                    placeholder='Historia'
                                                />
                                            </div>
                                            <p className='text-light'>{maxLength - text.length} caracteres restantes</p>
                                            <div className="col-12 col-sm-12 text-center mb-3 upload-container">
                                                <label htmlFor="file-input" className='text-center'>
                                                    <label className="form-label text-light">Archivo .pdf de tu Predial</label>
                                                    <div id="filePDF" className="upload-button"> Súbelo aquí</div>
                                                </label>
                                                <input id="file-input" type="file" accept="application/pdf,image/*" onChange={SendDocument} />
                                            </div>
                                            <div className="col-12 col-sm-12 text-center mb-2">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>Ingresa las fotos de tu casa ya sea Baño, Comedor, Sala, Dormitorio y entre más mejor</p>
                                                </div>
                                                <aside style={thumbsContainer}>
                                                    {thumbs}
                                                </aside>
                                            </div>
                                            <label className="text-start mb-1 text-light">
                                                <input type="checkbox" className="terminos" checked={showButton} onChange={handleCheckboxChange} />
                                                {"  " + "He leído y acepto los Terminos & Condiciones"}
                                            </label>
                                            <br />
                                            {
                                                dataOK == true ?
                                                    <div className='container text-center mt-4'>
                                                        <div className="spinner-border text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="text-center mt-3 mb-2">
                                                        {showButton && <button id="botonExam" className="btn btn-exam text-center upload-button" type="submit" onClick={Verificar}>Enviar Registro</button>}
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <div id="obtnerID" className="">
                    <div className='container contenedorObtenerCuenta'>
                        <button className="btn btn-danger bg-transparent border-0" onClick={() => setIsFlipped((prev) => !prev)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                            </svg>
                        </button>
                        <h2 className='text-center mb-5 titulos'>Obtener  ID de CuentaHabiente</h2>
                        <div className='row'>
                            <div className='col col-12 mb-3'>
                                <input className="form-control formulario" type="text" id="nombreCuenta" placeholder='Nombre' value={nombreCuenta} onChange={onChangeData} required></input>
                            </div>
                            <div className='col col-12 mb-3'>
                                <input className="form-control formulario" type="text" id="apellidoCuenta" placeholder="Apellidos" value={apellidoCuenta} onChange={onChangeData} required></input>
                            </div>
                            <div className='col col-12 mb-3'>
                                <input className="form-control formulario" type="email" id="correoCuenta" placeholder="Correo Electrónico" value={correoCuenta} onChange={onChangeData} required></input>
                            </div>
                            <div className='col-12 text-center'>
                                <button className="btn btn-danger btn-lg mt-4 upload-button" type="button" id="button-addon2" onClick={EnviarDatosCuentaHabiente}> Enviar</button>
                            </div>
                        </div>

                    </div>
                </div>
            </ReactCardFlip>

        </>
    )
}
export default Registro;