import { Link } from 'react-scroll'
import { HashLink } from 'react-router-hash-link'
const Header = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg fixed-top d-block">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                        <ul className="navbar-nav text-center">
                            <li className="nav-item">
                                <img src={process.env.PUBLIC_URL + "/images/assets/Enchulame.png"} width="115px" />
                            </li>
                            <li className="nav-item">
                                <Link activeClass="active" className="nav-link" to="home" spy={true} smooth={true} duration={300} >Inicio</Link>
                            </li>
                            {/*<li className="nav-item">
                                <Link activeClass="active" className="nav-link" to="ganador" spy={true} smooth={true} duration={300} >Seleccionados</Link>
                            </li>*/}
                                <li className="nav-item">
                                <Link activeClass="active" className="nav-link" to="premio" spy={true} smooth={true} duration={300} >Premio</Link>
                            </li>
	    {/*

                            <li className="nav-item">
                                <Link activeClass="active" className="nav-link" to="registro" spy={true} smooth={true} duration={300} >Registro</Link>
                            </li>
	      */}
	
                            <li className="nav-item">
                                <Link activeClass="active" className="nav-link" to="anteriores" spy={true} smooth={true} duration={300} >Anteriores</Link>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link' href='https://www.marthadebayle.com/sin-categoria/politica-de-privacidad/' target='_blank'>Aviso de Privacidad</a>
                            </li>
                            <li className="nav-item">
                                <Link activeClass="active" className="nav-link" to="terminos" spy={true} smooth={true} duration={300} >Términos y condiciones</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header;
