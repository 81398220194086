const Premio =()=>{
    return(
        <>
            <div id="premio" className="section">
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col text-center">
                            <h1 className="titulos text-center">Premio</h1>
                            <img src={process.env.PUBLIC_URL+"/images/assets/300mil.png"} className="imgPremio"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}   
export default Premio;