import React, { useEffect, useState } from "react";
import Home from "./Home";
import Participar from "./Participar";
import Registro from "./Registro";
import Ganador from "./Ganador";
import Terminos from "./Terminos";
import Anteriores from "./Anteriores";
import Footer from "./Footer";
import Premio from "./Premio";

const Main = () => {
    const [perView, setperView] = useState(null);
    useEffect(() => {
        setSlidePerViewPremios();
    }, []);

    window.addEventListener("resize", function (event) {
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(function () {
            setSlidePerViewPremios();
        }, 250);
    });

    function setSlidePerViewPremios() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width >= 1200) {
            setperView(3);
        }
        else if (width >= 800) {
            setperView(2);
        }
        else {
            setperView(1);
        }
    }
    return (
        <>
            <div>
                <Home  number={perView}/>
                {/*<Ganador  number={perView}/>*/}
                <Premio/>
                {/* <Registro number={perView} />*/}
                {/*<Participar number={perView} />*/}
                <Anteriores/>
                <Terminos/>
                <Footer/>
            </div>
        </>
    );
};

export default Main;
