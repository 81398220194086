import logo from './logo.svg';
import './App.css';
import { Routes, Navigate, Route, HashRouter, } from "react-router-dom";
import Header from './Componentes/Header';
import Participar from './Componentes/Participar';
import Home from './Componentes/Home';
import Registro from './Componentes/Registro';
import Main from './Componentes/Main';
import Terminos from './Componentes/Terminos';


function App() {
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <Routes>
          <Route path='*'>
            <Route exact path='home' element={<Main />} />
            <Route exact path='terminos' element={<Terminos/>}/>
          </Route>
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
