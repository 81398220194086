import { Element, Link } from 'react-scroll'
const Home = () => {
    return (
        <>
            <Element id="home" name="home" className="section" >
                <div className='container text-center'>
                    <img src={process.env.PUBLIC_URL + "/images/assets/logo-r.png"} className='size-img' />
                </div>
                <div className='container text-center'>
		    {/*<Link activeClass="active" className="nav-link" to="registro" spy={true} smooth={true} duration={300} >  <img src={process.env.PUBLIC_URL + "/images/assets/boton-2.png"} className='size-btn' /></Link>*/}
                    <h1 className='titulos2 mt-2'>DGRTC/0876/2024</h1>
                </div>
                <div className='container text-center d-flex justify-content-center mt-5 mb-5'>
                    <div className='row'>
                        <div className='col pasos'>
                            <img src={process.env.PUBLIC_URL + "/images/assets/03.png"} className="iconos mb-3" />
                            <h5 className='steps'>PASO 1</h5>
                            <h5 className='textHome'>nomina a tu mamá</h5>
                        </div>
                        <div className='col pasos'>
                            <img src={process.env.PUBLIC_URL + "/images/assets/02.png"} className="iconos mb-3" />
                            <h5 className='steps'>PASO 2</h5>
                            <h5 className='textHome'>participen</h5>
                        </div>
                        <div className='col pasos'>
                            <img src={process.env.PUBLIC_URL + "/images/assets/01.png"} className="iconos mb-3" />
                            <h5 className='steps'>PASO 3</h5>
                            <h5 className='textHome'>esperen la selección</h5>
                        </div>
                    </div>
                </div>
            </Element>
        </>
    )
}
export default Home;
