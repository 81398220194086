import { Link } from 'react-scroll'
const Terminos = () => {
    return (
        <>
            <div id="terminos" >
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-12 text-center mt-5 mb-2">
                            <h1 className="titulos">Términos y Condiciones</h1>
                        </div>
                        <div className="col-12">
                            <div className="container contenedorTerminos">
                                <h3 className="titulos mb-4">BASES Y CONDICIONES</h3>
                                <ul className="list-group-numbered">
                                    <li className="list-group-item mb-1">Podrán participar hombres y mujeres mexicanos, que habiten en la
                                        República Mexicana, que quieran que se remodele la casa o departamento
                                        en la que habita su mamá o mujeres que sean mamás.</li>
                                    <li className="list-group-item mb-1">Deberán ser
                                        mayores de 18 años de edad (contar con identificación oficial vigente).</li>
                                    <li className="list-group-item mb-1">
                                        Deberán ser cuentahabientes del programa de radio &quot;Martha Debayle en
                                        W&quot;. Cualquier persona que quiera ser CUENTAHABIENTE puede
                                        registrarse de manera GRATUITA en la página de W Radio
                                        http://www.wradio.com.mx/ y en la página de Martha Debayle
                                        http://marthadebayle.com/, donde una vez que se registren obtendrán un
                                        número único que será su registro de CUENTAHABIENTE. No existe
                                        ninguna limitación para ser CUENTAHABIENTE sólo hay que registrarse.
                                    </li>
                                    <li className="list-group-item mb-1">
                                        La casa o departamento que se remodelará deberá estar ubicado en zona
                                        urbana de cualquier parte de la República Mexicana y tener una superficie
                                        no mayor a 250 metros cuadrados de construcción.
                                    </li>
                                    <li className="list-group-item mb-1">
                                        Deberá presentar copia del título de propiedad y boleta de impuesto de
                                        predial o documento que ampare la superficie de construcción de la casa o
                                        departamento, y en su caso, contar con autorización por escrito del dueño
                                        del inmueble para llevar a cabo la remodelación. Así como copia de la
                                        identificación oficial de la mamá ganadora y del titular de la casa o
                                        departamento.
                                    </li>
                                    <li className="list-group-item mb-1">
                                        Deberán estar conscientes, asumir y aceptar las incomodidades que
                                        lleguen a tener durante el tiempo que se lleve a cabo la remodelación de la
                                        casa o departamento.
                                    </li>
                                    <li className="list-group-item mb-1">
                                        En caso de que el ganador o la mamá ganadora no cumpla con todas las
                                        bases a la hora de mencionar la historia ganadora, Martha Debayle podrá
                                        escoger la segunda mejor historia y así sucesivamente hasta tener un
                                        ganador o mamá ganadora que cumpla todas las bases.
                                    </li>
                                </ul>
                                <h3 className="titulos">MECÁNICA</h3>
                                <p className="textoTerminos">
                                    <strong>Del 16 de mayo al 16 de de juniode 2024</strong>, las personas interesadas en participar
                                    deberán ingresar a la página de Internet, en el apartado de &quot;Enchulame la casa
                                    2024&quot; en www.wradio.com.mx, en www.marthadebayle.com o en
                                    enchulamelacasa.com.mx y escribir en sólo 500 caracteres las razones por las
                                    que se merecen ganar la remodelación de la casa o departamento (de la mamá

                                    del ganador o de la propia mamá), así como anexar fotografías de diferentes
                                    espacios de la casa o departamento a remodelar (baños, cocina, sala, comedor,
                                    estudio, recámaras, etcétera). El registro del participante incluye: Nombre
                                    completo, edad, número de cuentahabiente, teléfono de contacto, dirección del
                                    domicilio, dirección de correo electrónico.
                                </p>
                                <p className="textoTerminos">
                                    Las historias serán publicadas en www.wradio.com.mx y en
                                    www.marthadebayle.com. Únicamente participarán las historias publicadas en
                                    dicho sitio. Las historias se publicarán a partir del 21 de junio.
                                </p>
                                <p className="textoTerminos">
                                    <strong>El 21 de junio de 2024</strong>, un jurado integrado por la producción del programa de
                                    radio &quot;Martha Debayle en W” seleccionará a la persona ganadora, tomando en
                                    cuenta a quien tenga la habilidad de crear la historia más original, creativa y
                                    conmovedora, en presencia del Supervisor de la Secretaría de Gobernación. La
                                    persona ganadora será contactada por teléfono o correo electrónico. Se le
                                    marcará 3 veces al número de contacto que registre y se le enviará un correo
                                    electrónico. Tendrá 24 horas para contactar a la producción.
                                </p>
                                <p className="textoTerminos">
                                    <strong>El 28 de junio de 2024</strong>, el ganador o la mamá ganadora asistirán al programa en
                                    vivo &quot;Martha Debayle en W&quot;, para lo cual, los gastos de transportación correrán
                                    por cuenta del ganador o la mamá ganadora.
                                </p>
                                <p className="textoTerminos">
                                    A más tardar el <strong>30 de octubre de 2024 </strong>, la casa o departamento se entregará
                                    remodelada a la mamá del ganador o a la mamá ganadora del Concurso en
                                    presencia del Supervisor de la Secretaría de Gobernación.
                                </p>
                                <h3 className="titulos text-center">PREMIO</h3>
                                <p className="textoTerminos">
                                    Valor total aproximado del premio: $300,000.00 M.N. (Trescientos mil Pesos
                                    00/100 Moneda Nacional), consistente en la remodelación de una casa o
                                    departamento que incluye:
                                </p>
                                <ul>
                                    <li className="textoTerminos">Pintura interior de toda la casa o departamento. Con un valor de $50,000
                                        (cincuenta mil pesos 00/100 M.N.)</li>
                                    <li className="textoTerminos">Muebles: 1 sala, 1 comedor, 2 recámaras. Con un valor de $40,000
                                        (cuarenta mil pesos 00/100 M.N)</li>
                                    <li className="textoTerminos">Muebles de baño (1 a 2 baños completos). Con un valor de $10,000
                                        (diez mil pesos 00/100 M.N)</li>
                                    <li className="textoTerminos">Piso o loseta en toda la casa o departamento. Con un valor de $40,000
                                        (cuarenta mil pesos 00/100 M.N)</li>
                                    <li className="textoTerminos">Cocina con alacena, 1 tarja y 1 estufa. Con un valor de $30,000 (treinta
                                        mil pesos 00/100 M.N.)</li>
                                    <li className="textoTerminos">Cortinas o persianas para todas las ventanas de la casa o
                                        departamento. Con un valor de $20,000 (veinte mil pesos 00/100 M.N)</li>
                                    <li className="textoTerminos">Línea blanca: 1 refrigerador, 1 lavadora de ropa, 1 microondas, 1
                                        secadora de ropa, 1 licuadora. Con un valor de $60,000 (sesenta mil
                                        pesos 00/100 M.N)</li>
                                    <li className="textoTerminos">Electrónicos: 1 pantalla de TV, 1 Apple TV, 1 sistema de audio (Mini
                                        componente). Con un valor de $30,000 ( treinta mil pesos 00/100 M.N)</li>
                                    <li className="textoTerminos">Suministro e instalación de 6 lámparas y/o focos Con un valor de
                                        $10,000 (diez mil pesos 00/100 M.N.)</li>
                                    <li className="textoTerminos">2 clóset. Con un valor de $3,000 (tres mil pesos 00 /100 M.N)</li>
                                    <li className="textoTerminos">20 cajas de plástico para organización. Con un valor de $5,000 (cinco
                                        mil pesos M.N)</li>
                                    <li className="textoTerminos">Artículos decorativos para toda la casa o departamento. Con un valor de
                                        $2,000 (dos mil pesos 00/100 M.N)</li>
                                </ul>
                                <p className="textoTerminos"> La remodelación no considera ni compromete modificar aspectos estructurales de
                                    la casa como son:</p>
                                <ul>
                                    <li className="textoTerminos">Instalación eléctrica o hidráulica</li>
                                    <li className="textoTerminos">Instalación de gas</li>
                                    <li className="textoTerminos">Red sanitaria</li>
                                    <li className="textoTerminos">Cimientos</li>
                                    <li className="textoTerminos">Techos, columnas, paredes o trabes</li>
                                </ul>
                                <p className="textoTerminos"> Para que el ganador pueda reclamar su premio es indispensable presentar
                                    identificación oficial vigente y copia de comprobante de domicilio.</p>
                                <p className="textoTerminos">En caso de que el premio no sea reclamado por el ganador, en un plazo de 90
                                    (noventa) días siguientes a la celebración del Concurso, dicho premio podrá ser
                                    donado a la beneficencia pública o privada de la elección de la Radiodifusora o ser
                                    reutilizado para futuras promociones.</p>

                                <div className='container text-center'>
				    {/*<Link activeClass="active" className="nav-link" to="registro" spy={true} smooth={true} duration={300} >  <img src={process.env.PUBLIC_URL + "/images/assets/boton-2.png"} className='size-btn' /></Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Terminos;
